import React from 'react';
import { Link } from 'react-router-dom';
import '../css/home.css';


const Home = () => {
  return (
    <div className="ui mainContainer">
      <div className="bird-container bird-container--one">
    		<div className="bird bird"></div>
    	</div>

      <div className="bird-container bird-container--two">
        <div className="bird bird"></div>
      </div>


      <div id="nameContainer" class="ui container">
        <h1>
          <span style={{fontWeight:700}}>BRANDON</span>
          <span style={{fontWeight:100}}>WONG</span>
        </h1>
        <h2 className="subheading">PRODUCT DESIGNER</h2>

        <h3>
          <Link to="/projects" >MY WORK</Link>
          <span style={{ color:'#fff', fontSize:'20px' }}> | </span>
          <Link to="/about" > ABOUT</Link>
          <span style={{ color:'#fff', fontSize:'20px' }}> | </span>
          <Link to="/contact" > CONTACT</Link>
        </h3>
      </div>
    </div>
  );
};

export default Home;
