import React from 'react';
import UIGallery from './gallery';

const GalleryPage = () => {
  return (
    <div style={{marginBottom:'60px'}}>
      <UIGallery />
    </div>
  )
}

export default GalleryPage;
