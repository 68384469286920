import { combineReducers } from 'redux';
import projects from '../lib/projects.json';

const projectListReducer = () => {
  return projects;
};

const projectSelectReducer = (selectedProject = null, action) => {
  if(action.type === 'FETCH_PROJECT'){
    return action.payload
  }
  return {selectedProject};
};

export default combineReducers({
  projects: projectListReducer,
  selectedProject: projectSelectReducer
})
