import React from 'react';
import '../css/header.css';
import { NavLink } from 'react-router-dom';

class Header extends React.Component {
  render() {
    return(
        <div className="ui top stackable menu my-header">
          <NavLink to="/" className="my-logo" >
            BRANDONWONG
          </NavLink>

          <div className="right menu my-menu">
            <NavLink to="/projects" className="item" activeClassName="active">My Work</NavLink>
            <NavLink to="/about" className="item" activeClassName="active">About Me</NavLink>
            <NavLink to="/contact" className="item" activeClassName="active">Contact</NavLink>
          </div>
        </div>
    );
  }
};

export default Header;
