import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from './header';

class ProjectList extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  renderList() {
    return this.props.projects.map((project) => {
      return (
        <Link to={`projects/${project.id}`} key={project.title} >
          <div className="ui link cards" style={{ marginBottom: '20px' }}>
            <div className="card">
              <div className="image">
                <img alt={project.title} src={project.cardImg} />
              </div>

              <div className="content">
                <div className="header"
                  style= {{
                    fontWeight: "100",
                    fontSize: "25px",
                    marginTop: '-.21425em',
                    lineHeight: "2.285714em",
                  }}
                >{project.title}</div>
                <div className="meta">
                  {project.type}
                </div>

                <div className="description">
                  {project.description}
                </div>
              </div>
            </div>
          </div>
        </Link>
      );
    });
  }


  render() {
    return(
      <div>
        <Header />

        <div className="ui grid container" style={{ marginTop: '30px', justifyContent: "center" }}>
          {this.renderList()}
        </div>
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    projects: Object.values(state.projects)
  };
}

export default connect(mapStateToProps)(ProjectList);
