import React from 'react';
import '../css/table.css';

const Table = () => {
  return (
    <div>
      <p className="tableHeading">New avatar usage by users after 2 weeks</p>

      <table className="border">
        <tbody><tr className="headerRow">
          <td className="cellstyle">Country code</td>
          <td className="cellstyle">Schools</td>
          <td className="cellstyle">Users</td>
          <td className="cellstyle">Avatars saved</td>
        </tr>
        <tr className="row">
          <td className="cellstyle">Total</td>
          <td className="cellstyle">11,170</td>
          <td className="cellstyle">366,988</td>
          <td className="cellstyle">546,983</td>
        </tr>
        <tr className="row">
          <td className="cellstyle">GBR</td>
          <td className="cellstyle">3,759</td>
          <td className="cellstyle">124,284</td>
          <td className="cellstyle">179,566</td>
        </tr>
        <tr className="row">
          <td className="cellstyle">AUS</td>
          <td className="cellstyle">3,634</td>
          <td className="cellstyle">112,666</td>
          <td className="cellstyle">167,026</td>
        </tr>
        <tr className="row">
          <td className="cellstyle">CAN</td>
          <td className="cellstyle">1,654</td>
          <td className="cellstyle">41,062</td>
          <td className="cellstyle">60,251</td>
        </tr>
        <tr className="row">
          <td className="cellstyle">NZL</td>
          <td className="cellstyle">757</td>
          <td className="cellstyle">35,484</td>
          <td className="cellstyle">61,742</td>
        </tr>
        <tr className="row">
          <td className="cellstyle">USA</td>
          <td className="cellstyle">567</td>
          <td className="cellstyle">17,754</td>
          <td className="cellstyle">27,665</td>
        </tr>
        <tr className="row">
          <td className="cellstyle">MEX</td>
          <td className="cellstyle">103</td>
          <td className="cellstyle">9,736</td>
          <td className="cellstyle">13,513</td>
        </tr>
        <tr className="row">
          <td className="cellstyle">ZAF</td>
          <td className="cellstyle">180</td>
          <td className="cellstyle">7,117</td>
          <td className="cellstyle">10,117</td>
        </tr>
        <tr className="row">
          <td className="cellstyle">IRL</td>
          <td className="cellstyle">102</td>
          <td className="cellstyle">2,626</td>
          <td className="cellstyle">4,166</td>
        </tr>
        <tr className="row">
          <td className="cellstyle">CHN</td>
          <td className="cellstyle">33</td>
          <td className="cellstyle">931</td>
          <td className="cellstyle">1,314</td>
        </tr>
        <tr className="row">
          <td className="cellstyle">Others</td>
          <td className="cellstyle">381</td>
          <td className="cellstyle">15,328</td>
          <td className="cellstyle">21,623</td>
        </tr>
      </tbody></table>



      <p className="tableHeading">New avatar usage by year group after 2 weeks</p>

      <table className="border">
        <tbody><tr className="headerRow">
          <td className="cellstyle">Year group</td>
          <td className="cellstyle">Users</td>
          <td className="cellstyle">Avatars created</td>
        </tr>
        <tr className="row">
          <td className="cellstyle">Total</td>
          <td className="cellstyle">369,793</td>
          <td className="cellstyle">550,880</td>
        </tr>
        <tr className="row">
          <td className="cellstyle">0</td>
          <td className="cellstyle">5,454</td>
          <td className="cellstyle">7,285</td>
        </tr>
        <tr className="row">
          <td className="cellstyle">1</td>
          <td className="cellstyle">18,762</td>
          <td className="cellstyle">25,831</td>
        </tr>
        <tr className="row">
          <td className="cellstyle">2</td>
          <td className="cellstyle">35,275</td>
          <td className="cellstyle">50,288</td>
        </tr>
        <tr className="row">
          <td className="cellstyle">3</td>
          <td className="cellstyle">58,411</td>
          <td className="cellstyle">88,168</td>
        </tr>
        <tr className="row">
          <td className="cellstyle">4</td>
          <td className="cellstyle">71,469</td>
          <td className="cellstyle">108,890</td>
        </tr>
        <tr className="row">
          <td className="cellstyle">5</td>
          <td className="cellstyle">76,706</td>
          <td className="cellstyle">117,730</td>
        </tr>
        <tr className="row">
          <td className="cellstyle">6</td>
          <td className="cellstyle">65,802</td>
          <td className="cellstyle">98,529</td>
        </tr>
        <tr className="row">
          <td className="cellstyle">7</td>
          <td className="cellstyle">20,717</td>
          <td className="cellstyle">30,322</td>
        </tr>
        <tr className="row">
          <td className="cellstyle">8</td>
          <td className="cellstyle">10,421</td>
          <td className="cellstyle">14,698</td>
        </tr>
        <tr className="row">
          <td className="cellstyle">9</td>
          <td className="cellstyle">4,580</td>
          <td className="cellstyle">6,272</td>
        </tr>
        <tr className="row">
          <td className="cellstyle">10</td>
          <td className="cellstyle">1,496</td>
          <td className="cellstyle">1,977</td>
        </tr>
        <tr className="row">
          <td className="cellstyle">11</td>
          <td className="cellstyle">519</td>
          <td className="cellstyle">656</td>
        </tr>
        <tr className="row">
          <td className="cellstyle">12</td>
          <td className="cellstyle">152</td>
          <td className="cellstyle">192</td>
        </tr>
      </tbody></table>
    </div>
  );
};

export default Table;
