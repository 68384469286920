import React from 'react';
import Header from './header';
import '../css/contact.css';

const Contact = () => {
  return (
    <div>
      <Header />
      <div className="ui container marginTop">
        <h2>Contact</h2>
        <p>Contact me through one of the following channels</p>

        <div className="ui marginTop">
          <div className="marginBottom">
            <label>Email</label>
            <a href="mailto:create@brandonwong.com.au?Comment%20from%20website">
              <h4>create@brandonwong.com.au</h4>
            </a>
          </div>

          <div>
            <label>Linkedin</label>
            <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/wongbrandon/">
              <h4>https://www.linkedin.com/in/wongbrandon/</h4>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact;
