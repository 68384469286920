import projects from '../lib/projects.json';

export const fetchProject = (id) => {
  return {
    type: 'FETCH_PROJECT',
    payload: {
      title: projects[id].title,
      type: projects[id].type,
      description: projects[id].description,
      introTxt: projects[id].introTxt,
      introImg: projects[id].introImg,
      challengeTxt: projects[id].challengeTxt,
      researchTxt: projects[id].researchTxt,
      researchImgLeft: projects[id].researchImgLeft,
      researchImgRight: projects[id].researchImgRight,
      sketchingImg: projects[id].sketchingImg,
      sketchTxt: projects[id].sketchTxt,
      prototypeURL: projects[id].prototypeURL,
      prototypeTxt: projects[id].prototypeTxt,
      endImg: projects[id].endImg,
      endTxt: projects[id].endTxt
    }
  };
};
