import React from 'react';
import Header from './header';
import '../css/about.css';

class About extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <Header />

        <div className="ui container marginTop">

          <div className="ui grid marginBottom">

            <div className="container" style={{ marginBottom:"20px" }}>
              <h2>Skills</h2>
            </div>

            <div className="container " style={{ display:"flex", width:"100%", justifyContent:"space-evenly"}}>

              <div className="container" style={{ width:"50%" }}>
                <table className="ui celled padded table large" style={{ width:"90%" }}>
                  <tbody>
                    <tr>
                      <td className="single line">
                        Sketch
                      </td>
                      <td>
                        <div className="ui star rating" data-rating="4" data-max-rating="4">
                          <i className="icon active"></i>
                          <i className="icon active"></i>
                          <i className="icon active"></i>
                          <i className="icon active"></i>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="single line">
                        HTML
                      </td>
                      <td>
                        <div className="ui star rating active icon" data-rating="3" data-max-rating="3">
                          <i className="icon active"></i>
                          <i className="icon active"></i>
                          <i className="icon active"></i>
                          <i className="icon active"></i>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="single line">
                        Illustrator
                      </td>
                      <td>
                        <div className="ui star rating active icon" data-rating="3" data-max-rating="3">
                          <i className="icon active"></i>
                          <i className="icon active"></i>
                          <i className="icon active"></i>
                          <i className="icon active"></i>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="single line">
                        UI
                      </td>
                      <td>
                        <div className="ui star rating active icon" data-rating="3" data-max-rating="3">
                          <i className="icon active"></i>
                          <i className="icon active"></i>
                          <i className="icon active"></i>
                          <i className="icon active"></i>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="single line">
                        Javascript
                      </td>
                      <td>
                        <div className="ui star rating active icon" data-rating="3" data-max-rating="3">
                          <i className="icon active"></i>
                          <i className="icon active"></i>
                          <i className="icon active"></i>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="container" style={{ width:"50%" }}>
                <table className="ui celled padded table large" style={{ width:"90%" }}>
                  <tbody>
                    <tr>
                      <td className="single line">
                        Figma
                      </td>
                      <td>
                        <div className="ui star rating" data-rating="4" data-max-rating="4">
                          <i className="icon active"></i>
                          <i className="icon active"></i>
                          <i className="icon active"></i>
                          <i className="icon active"></i>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="single line">
                        CSS
                      </td>
                      <td>
                        <div className="ui star rating active icon" data-rating="3" data-max-rating="3">
                          <i className="icon active"></i>
                          <i className="icon active"></i>
                          <i className="icon active"></i>
                          <i className="icon active"></i>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="single line">
                        Photoshop
                      </td>
                      <td>
                        <div className="ui star rating active icon" data-rating="3" data-max-rating="3">
                          <i className="icon active"></i>
                          <i className="icon active"></i>
                          <i className="icon active"></i>
                          <i className="icon active"></i>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="single line">
                        Blockchain
                      </td>
                      <td>
                        <div className="ui star rating active icon" data-rating="3" data-max-rating="3">
                          <i className="icon active"></i>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="single line">
                        React JS
                      </td>
                      <td>
                        <div className="ui star rating active icon" data-rating="4" data-max-rating="4">
                          <i className="icon active"></i>
                          <i className="icon active"></i>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
        </div>

        <div className="ui marginTop marginBottom">
          <div className="container">
            <h2>Interests</h2>
          </div>

            <div className="ui eighteen column grid container stackable" style={{ display:'flex', justifyContent:'space-between', marginTop: '15px'}}>
              <div className="three column wide marginTop" style={{ display:'flex', flexDirection:'column', alignItems:'center' }}>
                <i className="icon code huge" style={{ marginBottom: '15px' }}></i>
                <label>Technology</label>
              </div>

              <div className="three column wide marginTop" style={{ display:'flex', flexDirection:'column', alignItems:'center' }}>
                <i className="icon paint brush huge" style={{ marginBottom: '15px' }}></i>
                <label>Art/Design</label>
              </div>

              <div className="three column wide marginTop" style={{ display:'flex', flexDirection:'column', alignItems:'center' }}>
                <i className="icon headphones huge" style={{ marginBottom: '15px' }}></i>
                <label>Music</label>
              </div>

              <div className="three column wide marginTop" style={{ display:'flex', flexDirection:'column', alignItems:'center' }}>
                <i className="icon paper plane huge" style={{ marginBottom: '15px' }}></i>
                <label>Travel</label>
              </div>

              <div className="three column wide marginTop" style={{ display:'flex', flexDirection:'column', alignItems:'center' }}>
                <i className="icon heartbeat huge" style={{ marginBottom: '15px' }}></i>
                <label>Health/Sport</label>
              </div>

              <div className="three column wide marginTop" style={{ display:'flex', flexDirection:'column', alignItems:'center' }}>
                <i className="icon graduation cap huge" style={{ marginBottom: '15px' }}></i>
                <label>Learning</label>
              </div>
            </div>
          </div>

        <div className="ui container marginTop marginBottom stackable">
          <h2>Bio</h2>
          <div className="ui">
            <img alt="Brandon" src="http://www.brandonwong.com.au/img/brandon.png" class="ui centered image medium fluid" />
            <p style={{marginTop:'15px'}}>I am a product designer from Sydney, starting my journey in the print field, as technology advanced I transitioned into digital design, eventually finding a passion in end-to-end product design with a focus on UI and functionality.
            <br/><br/>
            As I've grown, so have my passions. Design would always be at the core but I developed a keen interest in coding and how my designs would translate to screens. This lead me to learning HTML, CSS and now I am learning React/Javascript in my spare time which has helped me have a stronger communication with develoepers. I remember starting my career as a designer, I vowed to never touch code!
            <br/><br/>
            Throughout my career I've learnt how important listening, clear communication and problem solving are to producing a product that everyone is proud of. I like to describe my role as a bridge between Product Owners/Managers and developers thus, it's important I understand precisely the requirements - by asking questions and listening - to be able to work well with developers.
            <br/><br/>
            Just as important is building empathy and understanding with users. To truly solve their problems, designers need to take the time to understand how their users think. It's quite an underrated perk of being a designer - I now have a better understanding of teachers (from my time at 3P Learning), real estate agents (CoreLogic) and mental health clinicians (Innowell).
            <br/><br/>
            I am extremely lucky to be able to intertwine my passions and my career. It means a lot for me to be in a role that allows me to evolve professionally but also personally.
            <br/><br/>
            Tim Minchin said "The best thing to do with your life is to learn as much as you can about as much as you can".
            </p>
          </div>
        </div>

      </div>
    </div>
  );
  }
};

export default About;
