import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchProject } from '../actions';
import '../css/page.css';
import Header from './header';
import Table from './table';
import GalleryPage from './galleryPage';

class Page extends Component {
  componentDidMount() {
    this.props.fetchProject(this.props.match.params.id);
    window.scrollTo(0, 0);
  }

  showGallery() {
    if(this.props.match.params.id === "7") {
      return <GalleryPage />
    }
  }

  showTable() {
    if(this.props.match.params.id === "4") {
      return <Table />
    }
  }

  showPrototype() {
    if(this.props.selectedProject.prototypeURL) {
      return (
        <div className="sixteen wide column marginTop">
          <iframe title="prototypeVideo" src={this.props.selectedProject.prototypeURL} width="100%" height="750" frameBorder="0" webkitallowfullscreen="" mozallowfullscreen="" allowFullScreen=""></iframe>
        </div>
      )
    }
  }

  showPrototypeTxt() {
    if(this.props.selectedProject.prototypeTxt) {
      return (
        <div className="twelve wide column marginTop">
          <div className="column">
            <h3>Prototyping</h3>
            {this.paraPrototype()}
          </div>
        </div>
      )
    }
  };

  showChallenge() {
    if(this.props.selectedProject.challengeTxt) {
      return (
        <div className="twelve wide column marginTop">
          <div className="column">
            <h3>Challenge</h3>
            {this.paraChallenge()}
          </div>
        </div>
      )
    }
  }

  showResearch() {
    if(this.props.selectedProject.researchTxt) {
      return (
        <div className="twelve wide column">
          <div className="column marginTop">
            <h3>Research</h3>
            {this.paraResearch()}
          </div>
        </div>
      )
    }
  }

  showSketch() {
    if(this.props.selectedProject.sketchTxt) {
      return (
        <div className="twelve wide column marginTop">
          <div className="column" >
            <div style={{ textAlign:'center' }}>
              <img alt="Sketch" src={this.props.selectedProject.sketchingImg} style={{ width:'75%', textAlign:'center', marginBottom: '100px' }} />
            </div>
            <h3>Sketching</h3>
            {this.paraSketch()}
          </div>
        </div>
      )
    }
  };

  showEnd() {
    if(this.props.selectedProject.endTxt) {
      return (
        <div className="twelve wide column marginTop marginBottom">
          <div className="column" >
            <div style={{ textAlign:'center' }}>
              <img alt="Final output" src={this.props.selectedProject.endImg} style={{ width:'90%', textAlign:'center', marginBottom: '100px' }} />
            </div>
            {this.showTable()}
            <h3>That's all!</h3>
            {this.paraEnd()}
          </div>
        </div>
      )
    }
  }

  showResearchImg() {
    if(this.props.selectedProject.researchImgLeft && this.props.selectedProject.researchImgLeft) {
      return (
        <div className="sixteen wide column" style={{ marginTop: '80px', padding: '0px', display: 'flex', justifyContent: 'space-evenly' }} >
          <div className="eight wide column">
            <img src={this.props.selectedProject.researchImgLeft} alt="Research 1" width='95%'/>
          </div>

          <div className="eight wide column">
            <img src={this.props.selectedProject.researchImgRight} alt="Research 2" width='95%'/>
          </div>
        </div>
      )
    }
  }

  showIntro() {
    if(this.props.selectedProject.introTxt) {
      return (
        <div className="six wide column marginTop" style={{ textAlign: 'center', display: 'flex', alignItems: 'center' }}>
          <img alt={this.props.selectedProject.title} src={this.props.selectedProject.introImg} width='100%'/>
        </div>
      )
    }
  }

  paraChallenge() {
    var challengeText = [];
    for (var text in this.props.selectedProject.challengeTxt) {
      challengeText.push(
        <p key={text}>{this.props.selectedProject.challengeTxt[text]}</p>
      );
    }
    return(
      <div>
        {challengeText}
      </div>
    )
  }

  paraResearch() {
    var researchText = [];
    for (var text in this.props.selectedProject.researchTxt) {
      researchText.push(
        <p key={text}>{this.props.selectedProject.researchTxt[text]}</p>
      );
    }
    return(
      <div>
        {researchText}
      </div>
    )
  }

  paraPrototype() {
    var prototypeText = [];
    for (var text in this.props.selectedProject.prototypeTxt) {
      prototypeText.push(
        <p key={text}>{this.props.selectedProject.prototypeTxt[text]}</p>
      );
    }
    return(
      <div>
        {prototypeText}
      </div>
    )
  }

  paraEnd() {
    var endText = [];
    for (var text in this.props.selectedProject.endTxt) {
      endText.push(
        <p key={text}>{this.props.selectedProject.endTxt[text]}</p>
      );
    }
    return(
      <div>
        {endText}
      </div>
    )
  }

  paraSketch() {
    var sketchText = [];
    for (var text in this.props.selectedProject.sketchTxt) {
      sketchText.push(
        <p key={text}>{this.props.selectedProject.sketchTxt[text]}</p>
      );
    }
    return(
      <div>
        {sketchText}
      </div>
    )
  }

  render(){
    return(
      <div>
      <Header />

        <div className="ui centered container stackable">
          <div className="ten wide column marginTop">
            <h2>{this.props.selectedProject.title}</h2>
            <p className="subHeading">{this.props.selectedProject.type}</p>
            <div className="ui divider"></div>
            <p>{this.props.selectedProject.introTxt}</p>
          </div>

          {this.showGallery()}

          {this.showIntro()}

          {this.showChallenge()}

          {this.showResearchImg()}

          {this.showResearch()}

          {this.showSketch()}

          {this.showPrototype()}

          {this.showPrototypeTxt()}

          {this.showEnd()}

          <div className="twelve wide column marginBottom backToProjects">
            <Link to='/projects' >
              Back to projects
            </Link>
          </div>

        </div>
      </div>
    );
  }
}


const mapStateToProps = (state, ownProps) => {
  return{
    project: state.projects,
    selectedProject: state.selectedProject
  }
}

export default connect(mapStateToProps, { fetchProject })(Page);
