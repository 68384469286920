import React from 'react';
import ImageGallery from 'react-image-gallery';

class UIGallery extends React.Component {

  render() {

    const images = [
      {
        original: 'https://brandonwong.com.au/img/PVNZ.png',
        thumbnail: 'https://brandonwong.com.au/img/PVNZ.png',
      },
      {
        original: 'https://brandonwong.com.au/img/RPD_1.png',
        thumbnail: 'https://brandonwong.com.au/img/RPD_1.png',
      },
      {
        original: 'https://brandonwong.com.au/img/RPD_2.png',
        thumbnail: 'https://brandonwong.com.au/img/RPD_2.png',
      },
      {
        original: 'https://brandonwong.com.au/img/OTH.png',
        thumbnail: 'https://brandonwong.com.au/img/OTH.png',
      },
      {
        original: 'https://brandonwong.com.au/img/printerpix.gif',
        thumbnail: 'https://brandonwong.com.au/img/printerpix.jpg',
      },
      {
        original: 'https://brandonwong.com.au/img/sloth.gif',
        thumbnail: 'https://brandonwong.com.au/img/sloth.jpg',
      },
      {
        original: 'https://brandonwong.com.au/img/election2015.png',
        thumbnail: 'https://brandonwong.com.au/img/election2015thumb.jpg',
      },
      {
        original: 'https://brandonwong.com.au/img/emailFull.jpg',
        thumbnail: 'https://brandonwong.com.au/img/emailThumb.jpg',
      },
      {
        original: 'https://brandonwong.com.au/img/tccRedesign.png',
        thumbnail: 'https://brandonwong.com.au/img/tccRedesignThumb.jpg',
      },
      {
        original: 'https://brandonwong.com.au/img/woolies.png',
        thumbnail: 'https://brandonwong.com.au/img/wooliesThumb.jpg',
      },
    ];

    return (
      <ImageGallery items={images} showPlayButton={false} showFullscreenButton={false} />
    );
  }

}

export default UIGallery;
