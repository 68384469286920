import React from 'react';
import { Router, Route } from 'react-router-dom';
import ProjectPage from './projectPage';
import ProjectList from './projectList';
import ProjectHome from './projectHome';
import About from './about';
import Contact from './contact';
import history from '../history';

const App = () => {
  return(
    <div>
      <Router history={history}>
        <Route path="/" exact component={ProjectHome} />
        <Route path="/about" exact component={About} />
        <Route path="/contact" exact component={Contact} />
        <Route path="/projects" exact component={ProjectList} />
        <Route path="/projects/:id" exact component={ProjectPage} />
      </Router>
    </div>
  );
};

export default App;
